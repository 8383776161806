<template>
    <comp-form
        title="运营商"
        ref="comp_form"
        submit-api="/gateway/api/sycompany/pc/operator/saveOperator"
        detail-api="/gateway/api/sycompany/pc/operator/queryById"
        id-key="operatorId"
        edit-api="/gateway/api/sycompany/pc/operator/updateById"
        :submit-before="onSubmitBefore"
        :data-before="onDataBefore"
        @on-submit="onReset"
    >
        <template v-slot:form-box="data">
            <ElemFormItem title="运营商名称" name="operatorName">
                <template v-slot:default="item">
                    <Input :style="item.cfg.style" maxlength="20" v-model="data.form.operatorName" :placeholder="item.cfg.ph"></Input>
                </template>
            </ElemFormItem>

            <ElemFormItem title="分账账号" name="merchantId">
                <template v-slot:default="item">
                    <Select v-model="data.form.merchantId" transfer :style="item.cfg.style" placeholder="请选择分账账号" filterable>
                        <Option :value="item.merchantId" v-for="(item, index) in selectList" :key="index">{{ item.merchantName }}</Option>
                    </Select>
                </template>
            </ElemFormItem>

            <ElemFormItem title="分账占佣金比例" name="prorateRatio">
                <template v-slot:default="item">
                    <InputNumber :style="item.cfg.style" :max="100" :min="0" v-model="data.form.prorateRatio" :placeholder="item.cfg.ph"></InputNumber>
                    <span style="margin-left: 5px">%</span>
                </template>
            </ElemFormItem>

            <!-- <ElemFormItem title="绑定供应商" name="superAdminAccount">
                <elem-label style="width:300px" title="请选择供应商" idKey="supplierId" valueKey="supplierName" :value="data.form.merchantId" @on-change="data.form.merchantId = $event.value.keys"></elem-label>
            </ElemFormItem> -->

            <ElemFormItem title="管理员账号" name="superAdminAccount">
                <elem-select
                    style="width: 300px"
                    placeholder="请输入用户名或手机号码进行搜索"
                    :remotely="{ api: '/gateway/apps/basic/api/base/uc/getByMobileOrNickname', search: 'mobileOrNickname', id: 'loginAccount', value: 'nickName' }"
                    :multiple="true"
                    :value="data.form.superAdminAccount"
                    @on-change="data.form.superAdminAccount = $event.value"
                ></elem-select>
            </ElemFormItem>

            <ElemFormItem title="启禁用" name="operatorStatus">
                <RadioGroup v-model="data.form.operatorStatus">
                    <Radio :label="'0'">启用</Radio>
                    <Radio :label="'1'">禁用</Radio>
                </RadioGroup>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../../jointly/components/CompForm.vue"
import ElemMap from "../../../jointly/components/ElemMap.vue"
import ElemLabel from "../../components/elem-label.vue"
import ElemSelect from "../../components/elem-select.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"
import Utils from "../../../jointly/utils/utils"

export default {
    components: { CompForm, ElemLabel, ElemSelect, ElemFormItem, ElemMap },

    data() {
        return {
            groups: null,
            isDisplayMap: false,
            selectList: [],
        }
    },

    mounted() {
        this.selectMerchantLabel()
    },

    methods: {
        selectMerchantLabel() {
            this.$get("/gateway/api/merchant/merchant/v1/selectMerchantLabel", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res && res.dataList && res.dataList.length) {
                    this.selectList = res.dataList
                } else {
                    this.selectList = [
                        {
                            merchantId: "001",
                            merchantName: "随约服务商",
                            parentId: "0",
                            parentName: null,
                        },
                        {
                            merchantId: "00100011",
                            merchantName: "广州市璟承餐饮服务有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100012",
                            merchantName: "沪上阿姨悠方天地店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100013",
                            merchantName: "广州藤原日式料理餐饮有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100014",
                            merchantName: "广州市茗荔圆餐饮管理服务有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100015",
                            merchantName: "广州市翊希餐饮服务有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100016",
                            merchantName: "广州市南沙区益轩砂锅粥店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100017",
                            merchantName: "广州市二号大院餐有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100018",
                            merchantName: "广州南沙区海景好佰年口腔门诊部有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100019",
                            merchantName: "广州市南沙区潮悦牛肉馆",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100020",
                            merchantName: "广州市家禾福顺家政服务有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100021",
                            merchantName: "广州心馨生活服务有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100022",
                            merchantName: "广州臻爱月府母婴护理服务中心(有限合伙)",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100023",
                            merchantName: "星嘉人(广州)康体有限责任公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100024",
                            merchantName: "广州市南沙区东涌东鱼饮食店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "00100025",
                            merchantName: "广州市南沙区东涌小叙饮食店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001001",
                            merchantName: "广东雄才文化发展有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001002",
                            merchantName: "颜悦（广州）供应链有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001003",
                            merchantName: "全球特色农产品供应链（广州）有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001004",
                            merchantName: "海西州电商绿洲商贸有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001005",
                            merchantName: "广州市番禺区大石花开四季花店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001006",
                            merchantName: "韶关市韶益农牧食品科技发展有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001007",
                            merchantName: "广州市南沙区井泽冰甜甜品店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001008",
                            merchantName: "广州杰浩餐饮有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001009",
                            merchantName: "广州市南沙区鸟一居酒屋餐饮店",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                        {
                            merchantId: "001010",
                            merchantName: "广州鳗师兄融合料理有限公司",
                            parentId: "001",
                            parentName: "随约服务商",
                        },
                    ]
                }
            })
        },
        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 数据前置事件
         */
        onDataBefore(data) {
            let datas = {
                ...data.archive,
                ...data,
            }
            const administrators = []

            if (datas.superAdminAccount && datas.superAdminAccount != "") {
                datas.superAdminAccount.split(",").map(item => {
                    administrators.push({
                        id: item,
                        value: item,
                    })
                })
            }
            // Utils.each(datas.superAdminAccount, v && v != '' ? ((v)=>{})() : v => {
            //     administrators.push({
            //         id: v.loginAccount,
            //         value: v.nickName,
            //     })
            // })

            datas.superAdminAccount = administrators

            return datas
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            return {
                operatorName: data.operatorName,
                operatorJson: "",
                oemCode: parent.vue.oemInfo.oemCode,
                operatorStatus: data.operatorStatus,
                archive: {
                    archiveId: data.archiveId || "",
                    superAdminAccount: data.superAdminAccount.join(","),
                    merchantId: data.merchantId,
                    prorateRatio: data.prorateRatio,
                },
            }
        },

        onClickMap(evt) {
            this.location = evt
        },

        onSaveLocation() {
            if (!this.location) {
                return this.$Message.error({
                    content: "请选择坐标",
                    background: true,
                })
            }

            this.$refs.comp_form.setValue({
                lng: this.location.lng,
                lat: this.location.lat,
            })

            this.isDisplayMap = false
        },
    },
}
</script>
